/** entry point of evenue module*/
(function() {
    var NAME = "et.evenue.Module";

    var C = et.constants;
    et.module.create(NAME, {
        onLoad : function() {
            //check siteId
            var siteId = et.getPageParameter(et.constants.SITEID);

            //marketing integration
            var rsrc = et.getQueryParameter("RSRC");
            var rdat = et.getQueryParameter("RDAT");
            if (rsrc) {
                $.cookie("referral_id", rsrc, {
                    path : "/"
                });
            }
            if (rdat) {
                $.cookie("referral_dat", rdat, {
                    path : "/"
                });
            }

            //parse the default params
            et.DefaultPageParams = {
            };
            /**forcing the siteId as uppercase*/
            et.siteId = et.DefaultPageParams[C.SITEID] = et.getPageParameter(C.SITEID);
            //et.siteId=et.siteId?et.siteId.toUpperCase():et.siteId;
            et.locale = et.DefaultPageParams[C.LOCALE] = et.getPageParameter(C.LOCALE);
            if(!et.locale){et.locale="en_US";}

            et.evLinkId = et.DefaultPageParams[C.LINKID] = et.getPageParameter(C.LINKID);

            //save for reference, pageControler will use this to decide if the page needs to be re-laoded.
            $.cookie(et.cookiePrefix + C.SITEID, et.siteId, {
                path : et.cookiePath
            });
			$.cookie(et.cookiePrefix + C.LINKID, et.evLinkId,
			{
				path : et.cookiePath
			});
            //load customization if present
            if (et.siteId) {
                //load site css
                et.loadCSS(["customize/" + et.siteId + "/css/site.css"]);

                //resolve sellerId/poolId
                var defer = et.resolveSiteId(et.siteId);
                defer.done(function(p_siteId, p_sellerId, p_poolId, p_locale, p_locales) {
                	et.poolId = p_poolId;
                	//set default locale if absent
                    et.locale = et.DefaultPageParams[C.LOCALE] = et.locale || p_locale || "en_US";
                    et.locales = p_locales;
                    loadResources(p_locale, p_locales).done(function() {
                        et.loadScript([["customize/" + et.siteId + "/script/override.js?_=" + Date.now()]], function() {
                            et.evenue.Module.load();
                        });
                    });
                });
                defer.fail(function() {
                    loadResources().done(function() {
                        et.evenue.Module.load();
                    });
                });
            } else {

                // TODO: remove hard coded values
                p_locale = et.locale;
                p_locales = [];
                p_locales.push(et.locale);

                loadResources(p_locale, p_locales).done(function() {

                    et.evenue.Module.load();
                });
            }
        },

        load : function() {
            $("body").load("global/view/layout.html?v=" + et.version, function() {

                et.loadScript([["global/controller/pageController.js", "global/controller/headController.js", "global/controller/footerController.js"]], function() {
                    et.evenue.HeadController.bind($("#header"));
                    //this can be replaced by customized  header
                    //et.evenue.NavBarController.bind($("#navbar"));//this can be replaced by customized  navbar
                    et.evenue.PageController.bind($("#view-content"));
                    et.evenue.FooterController.bind($("#footer"));
                    et.onModuleLoaded.fire();
                });
            });
        }
    });

    var loadResources = function(p_locale, p_locales) {
        var defer = $.Deferred();
        //load the resources (text, error messages)
        et.loadResources('common/resource/res', 'common/resource/errors_ss', 'common/resource/syscodes_ss', et.locale).done(function() {
            //et.evenue.Module.load();
            et.loadScript([["common/script/locale_widgets.js"]], function() {
                defer.resolve();
            });
            //defer.resolve();
        });
        $.cookie(et.cookiePrefix + C.LOCALE, et.locale, {
            path : et.cookiePath
        });
        return defer.promise();
    };

})();
